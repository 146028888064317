import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';


@Injectable({
  providedIn: 'root'
})
export class SearchQueryService {

    constructor(
      private http: HttpClient,
      private utils: UtilityService
    ) {}
  
    searchBookings(query: string, sortQuery: string, page: number, filterQuery: string, orderType: 'NEW' | 'INPRO'): Observable<any> {
      const startTime = performance.now();
      let params = new HttpParams()
        .set('query', query)
        .set('sortQuery', sortQuery)
        .set('page', page.toString())
        .set('filterQuery', filterQuery)
        .set('type', orderType);
  
      return this.http.get(
        `${environment.SERVER_URL}bookings/search`,
        {
          params,
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
          }
        }
      ).pipe(
        tap(() => {
          const endTime = performance.now();
          const duration = endTime - startTime;
          console.log(`HTTP request took ${duration} milliseconds`);
        })
      );
    }
  
}
