import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './module/shared/shared.module';
import { InvoiceContainerComponent } from './feature/invoice/invoice-container/invoice-container.component';
import { CommonModule } from '@angular/common';
import { MaterialUIModule } from './module/material-ui/material-ui.module';
import { OrderFlowStep1Component } from './feature/order/order-flow-step1/order-flow-step1.component';
import { OrderFlowStep3Component } from './feature/order/order-flow-step3/order-flow-step3.component';
import { DryCleaningListComponent } from './feature/invoice/dry-cleaning-list/dry-cleaning-list.component';
import { OrderFlowWrapperComponent } from './feature/order/order-flow-wrapper/order-flow-wrapper.component';
import { OrderFlowStep2Component } from './feature/order/order-flow-step2/order-flow-step2.component';
import { CustomerDetailFormComponent } from './feature/order/components/customer-detail-form/customer-detail-form.component';
import { OrderDetailFormComponent } from './feature/order/components/order-detail-form/order-detail-form.component';
import { AddAddressFormComponent } from './feature/order/components/add-address-form/add-address-form.component';
import { OrderHistoryListComponent } from './feature/order/components/order-history-list/order-history-list.component';
import { CustomerAddressDetailFormComponent } from './feature/order/components/customer-address-detail-form/customer-address-detail-form.component';
import { CustomerActivityDetailsComponent } from './feature/order/components/customer-activity-details/customer-activity-details.component';
import { CustomerDirectoryComponent } from './feature/dashboard/customer-directory/customer-directory.component';
import { CustomerOrderListingComponent } from './feature/dashboard/customer-order-listing/customer-order-listing.component';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalComponent } from './modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PremiumLaundryComponent } from './module/shared/component/premium-laundry/premium-laundry.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { LoginComponent } from './login/login.component';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
import { DailySalesReportComponent } from './reports/daily-sales-report/daily-sales-report.component';
import { GstReportComponent } from './reports/gst-report/gst-report.component';
import { DropCustomerReportComponent } from './reports/drop-customer-report/drop-customer-report.component';
import { MonthWiseCategoryReportComponent } from './reports/month-wise-category-report/month-wise-category-report.component';
import { CancelledOrdersReportsComponent } from './reports/cancelled-orders-reports/cancelled-orders-reports.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { PackageTableComponent } from './package-table/package-table.component';
import { CouponsTableComponent } from './coupons-table/coupons-table.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { AddCouponStep1Component } from './add-coupon/add-coupon-step1/add-coupon-step1.component';
import { AddCouponStep2Component } from './add-coupon/add-coupon-step2/add-coupon-step2.component';
import { AddCouponStep3Component } from './add-coupon/add-coupon-step3/add-coupon-step3.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatIconModule } from '@angular/material/icon';
import { LoaderComponent } from './loader/loader.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BarcodeComponent } from './barcode/barcode.component';
import { AddressComponent } from './address/address.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { ArchiveComponent } from './archive/archive.component';
import { RoutingComponent } from './routing/routing.component';
import { BackBtnComponent } from './back-btn/back-btn.component';
import { StoreProfileComponent } from './store-profile/store-profile.component';
import { StoreRunnerComponent } from './store-runner/store-runner.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { PricingComponent } from './pricing/pricing.component';
import { PriceSidebarComponent } from './price-sidebar/price-sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import * as LogRocket from 'logrocket';
import { SearchPipePipe } from './shared/pipe/search-pipe.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter/src/ng2-filter.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddCouponStep4Component } from './add-coupon/add-coupon-step4/add-coupon-step4.component';
import { PaymentModalComponent } from './feature/dashboard/payment-modal/payment-modal.component';
import { AmountModalComponent } from './feature/dashboard/amount-modal/amount-modal.component';
import { SuccessModalComponent } from './feature/dashboard/success-modal/success-modal.component';
import { GstDateComponentComponent } from './Dates-component/gst-date-component/gst-date-component.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ItemQuantityComponent } from './item-quantity/item-quantity.component';
import { AddNewItemComponent } from './add-new-item/add-new-item.component';
import { CouponFilterComponent } from './coupon-filter/coupon-filter.component';
import { EditGstDialogComponent } from './edit-gst-dialog/edit-gst-dialog.component';
import { BankEditDialogComponent } from './bank-edit-dialog/bank-edit-dialog.component';
import { CustomerDirectorySearchComponent } from './feature/dashboard/customer-directory-search/customer-directory-search.component';
import { CustomerDirectoryDateComponent } from './feature/dashboard/customer-directory-date/customer-directory-date.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DateRangeComponent } from './dashboard/date-range/date-range.component';
import { ActionComponent } from './dashboard/action/action.component';
import { CancelModalComponent } from './dashboard/cancel-modal/cancel-modal.component';
import { SortComponent } from './dashboard/sort/sort.component';
import { SearchComponent } from './dashboard/search/search.component';
import { PageIndexComponent } from './dashboard/page-index/page-index.component';
import { OrderSourceComponent } from './dashboard/order-source/order-source.component';
import { OrderReadyModalComponent } from './dashboard/order-ready-modal/order-ready-modal.component';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  declarations: [
    AppComponent,
    InvoiceContainerComponent,
    DryCleaningListComponent,
    OrderFlowWrapperComponent,
    OrderFlowStep2Component,
    CustomerDetailFormComponent,
    OrderDetailFormComponent,
    AddAddressFormComponent,
    OrderHistoryListComponent,
    CustomerAddressDetailFormComponent,
    CustomerActivityDetailsComponent,
    OrderFlowStep1Component,
    OrderFlowStep3Component,
    CustomerDirectoryComponent,
    CustomerOrderListingComponent,
    ModalComponent,
    PremiumLaundryComponent,
    NotificationsComponent,
    ConfirmationModalComponent,
    LoginComponent,
    AddCouponComponent,
    DailySalesReportComponent,
    GstReportComponent,
    DropCustomerReportComponent,
    MonthWiseCategoryReportComponent,
    CancelledOrdersReportsComponent,
    UpdateUserComponent,
    PackageTableComponent,
    CouponsTableComponent,
    AddCouponStep1Component,
    AddCouponStep2Component,
    AddCouponStep3Component,
    PaginationComponent,
    LoaderComponent,
    BarcodeComponent,
    AddressComponent,
    AddAddressComponent,
    ArchiveComponent,
    RoutingComponent,
    BackBtnComponent,
    StoreProfileComponent,
    StoreRunnerComponent,
    ViewInvoiceComponent,
    CommentBoxComponent,
    PricingComponent,
    PriceSidebarComponent,
    SearchPipePipe,
    AddCouponStep4Component,
    PaymentModalComponent,
    AmountModalComponent,
    SuccessModalComponent,
    GstDateComponentComponent,
    ItemQuantityComponent,
    AddNewItemComponent,
    CouponFilterComponent,
    EditGstDialogComponent,
    BankEditDialogComponent,
    CustomerDirectorySearchComponent,
    CustomerDirectoryDateComponent,
    DashboardComponent,
    DateRangeComponent,
    ActionComponent,
    CancelModalComponent,
    SortComponent,
    SearchComponent,
    PageIndexComponent,
    OrderSourceComponent,
    OrderReadyModalComponent,
    ClickOutsideDirective,
    CustomerDirectoryDateComponent,
    CustomerDirectorySearchComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    MaterialUIModule,
    MatDatepickerModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatDialogModule,
    MatIconModule,
    NgxBarcodeModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: FranchiseInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Initialize LogRocket
    LogRocket.init('cpsu6u/uclean');
  }
}