import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

interface BankDialogData {
  storeId: number;
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  bhimUpi: string;
}

interface BankForm {
  bank_name: string;
  bank_account: string;
  bank_ifsc: string;
  bhim_upi: string;
}

@Component({
  selector: 'app-bank-edit-dialog',
  templateUrl: './bank-edit-dialog.component.html',
  styleUrls: ['./bank-edit-dialog.component.scss']
})
export class BankEditDialogComponent implements OnInit {
  bankForm: BankForm = {
    bank_name: '',
    bank_account: '',
    bank_ifsc: '',
    bhim_upi: ''
  };

  isSubmitting = false;
  errors: Record<string, boolean> = {};
  errorMessages: Record<string, string> = {};

  constructor(
    private dialogRef: MatDialogRef<BankEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BankDialogData,
    private http: HttpClient,
    private utils: UtilityService
  ) {
    this.initForm();
  }

  ngOnInit(): void {}

  private initForm(): void {
    this.bankForm = {
      bank_name: this.data.bankName || '',
      bank_account: this.data.accountNumber || '',
      bank_ifsc: this.data.ifscCode || '',
      bhim_upi: this.data.bhimUpi || ''
    };
  }

  validateInput(field: keyof BankForm): void {
    this.errors[field] = false;
    this.errorMessages[field] = '';

    const value = this.bankForm[field];

    switch (field) {
      case 'bank_name':
        if (!value) {
          this.errors[field] = true;
          this.errorMessages[field] = 'Bank name is required';
        }
        break;

      case 'bank_account':
        if (!value) {
          this.errors[field] = true;
          this.errorMessages[field] = 'Account number is required';
        } else if (!/^[0-9]+$/.test(value)) {
          this.errors[field] = true;
          this.errorMessages[field] = 'Account number must contain only numbers';
        }
        break;

      case 'bank_ifsc':
        if (!value) {
          this.errors[field] = true;
          this.errorMessages[field] = 'IFSC code is required';
        } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
          this.errors[field] = true;
          this.errorMessages[field] = 'Invalid IFSC code format';
        }
        break;

      case 'bhim_upi':
        if (value && !/^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/.test(value)) {
          this.errors[field] = true;
          this.errorMessages[field] = 'Invalid UPI ID format';
        }
        break;
    }
  }

  validateForm(): boolean {
    const fieldsToValidate: Array<keyof BankForm> = ['bank_name', 'bank_account', 'bank_ifsc', 'bhim_upi'];
    let isValid = true;

    fieldsToValidate.forEach(field => {
      this.validateInput(field);
      if (this.errors[field] && field !== 'bhim_upi') {
        isValid = false;
      }
    });

    return isValid;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.validateForm()) {
      return;
    }

    this.isSubmitting = true;
    this.errorMessages['general'] = '';

    this.http.put<void>(
      `${environment.SERVER_URL}stores/${this.data.storeId}/banking-details?franchise=${environment.FRANCHISE}`,
      this.bankForm,
      {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
        }
      }
    ).subscribe({
      next: () => {
        this.dialogRef.close(this.bankForm);
      },
      error: (error: any) => {
        console.error('Error updating bank details:', error);
        this.isSubmitting = false;
        this.errorMessages['general'] = error.error?.message || 'Failed to update bank details';
      }
    });
  }
}