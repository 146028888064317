import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
// import { Chart } from 'chart.js';
import { Chart, registerables } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { StoreRunnerComponent } from '../store-runner/store-runner.component';
import { EditGstDialogComponent } from '../edit-gst-dialog/edit-gst-dialog.component';
import { BankEditDialogComponent} from '../bank-edit-dialog/bank-edit-dialog.component';
import { ChangeDetectorRef } from '@angular/core';
Chart.register(...registerables);
interface StoreSchedule {
  id: number;
  storeId: number;
  day: string;
  isOpen: boolean;
  openingTime: string;
  closingTime: string;
  createdAt: string;
  updatedAt: string | null;
}
interface Runner {
  id: number;
  name: string;
  role: string;
  status: number;
  mobile: string;
  email: string;
  created_at: string;
  updated_at: string;
}

interface RunnerCheckResponse {
  data: {
    id: number;
    name: string;
    mobile: string;
    stores: {
      store_id: number;
      store_name: string;
      status: number
    }[];
  };
    status: string;
}
@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss'],
})
export class StoreProfileComponent implements OnInit {
  public month: number;
  public year: number;
  public currentMonthData: any;
  public chart: any;
  public currentMonthGraphData: any[] = [];
  public previousMonthGraphData: any[] = [];
  public currentMonthGraphLabels: string[] = [];
  public dailySales: any;
  public currentDate: Date = new Date(Date.now());
  public totalSales: number = 0;
  public insights: any;
  public storedays: any;
  public assets = environment.assetUrl;
  public days: any;
  private storeId: number | null = null;
  public edit= `${environment.assetUrl}assets/images/icons/edit.svg`;

  public months: string[] = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public currentChart = 1;
  // @ViewChild('lineChart') private chartRef!;
  getStoreDays() {
    if (!this.storeId) {
      console.error('Store ID is not available');
      return;
    }

    this.http
      .get(`${environment.SERVER_URL}stores/storeschedule`, {
        params: {
          franchise: environment.FRANCHISE,
          store_id: this.storeId.toString()
        },
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((response: any) => {
        if (response.data && response.data.schedule) {
          // Sort days in correct order
          const daysOrder = [];
          this.storedays = response.data.schedule.sort((a: StoreSchedule, b: StoreSchedule) => 
            daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day)
          );
        }
      });
  }
  runners: Runner[] = [
    {
        id: 1,
        name: "John Doe",
        role: "Runner",
        status: 1,
        mobile: "123-456-7890",
        email: "john.doe@example.com",
        created_at: "2024-01-01",
        updated_at: "2024-01-15"
    },
    {
        id: 2,
        name: "Jane Smith",
        role: "Runner",
        status: 0,
        mobile: "987-654-3210",
        email: "jane.smith@example.com",
        created_at: "2023-12-01",
        updated_at: "2024-02-01"
    },
     {
        id: 3,
        name: "Alice Johnson",
        role: "Runner",
        status: 1,
        mobile: "111-222-3333",
        email: "alice.johnson@example.com",
        created_at: "2023-11-15",
        updated_at: "2024-03-01"
    },
];
showActivityModal: boolean = false;
selectedRunner: any = null; // To store the selected runner


openActivityModal(runner: Runner) {
   this.getRunnerActivity(runner.mobile);
}
closeActivityModal() {
this.showActivityModal = false;
  this.selectedRunner = null;
}


async getRunnerActivity(mobile: string){
  console.log("this si comirnwe");
    try {
        const response = await this.http.get<RunnerCheckResponse>(
            `${environment.SERVER_URL}runners/check/${mobile}?franchise=${environment.FRANCHISE}`,
            {
                headers: {
                    authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
                },
            }
        ).toPromise();
        if(response?.status === 'success'){
             this.selectedRunner = response?.data;
            this.showActivityModal = true;
        }else{
            alert('something went wrong');
        }
    } catch (error) {
        console.error('Error fetching runner activity:', error);
    }
}

  getRunners() {
    if (!this.storeId) {
      console.error('Store ID is not available');
      return;
    }

    this.http
      .get(`${environment.SERVER_URL}runners`, {
        params: {
          franchise: environment.FRANCHISE,
          store_id: this.storeId.toString()
        },
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.runners = data.data;
      });
  }
 


  getInsights() {
    this.http
      .get(`${environment.SERVER_URL}stores/getInsights`, {
        params: {
          franchise: environment.FRANCHISE
        },
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data.data);
        this.insights = data.data;
        this.storeId = this.insights?.store?.id;
        // After getting insights and store ID, fetch other data
        this.getChartData();
        this.getDaySales();
        this.getRunners();
        this.getStoreDays();
      });
  }

  getCurrentMonthName() {
    return new Date().toLocaleString('default', { month: 'long' });
  }

  getLastMonthName() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toLocaleString('default', { month: 'long' });
  }

  dailySalesCalc() {
    // this.dailySales?.map((el: any) => {
    // });
    let sum = 0;
    for (const obj in this.dailySales) {
      if (this.dailySales[obj]) {
        sum += this.dailySales[obj];
      }
    }
    this.totalSales = sum;
  }
  openEditGstDialog() {
    const dialogRef = this.dialogRef.open(EditGstDialogComponent, {
      data: {
        storeId: this.insights?.store?.id,
        currentGst: this.insights?.store?.gst
      },
      panelClass: 'bank-details-dialog-container',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the local GST value if the dialog was successful
        this.insights.store.gst = result;
      }
    });
  }
  openBankDetailsDialog() {
    const dialogRef = this.dialogRef.open(BankEditDialogComponent, {
      data: {
        storeId: this.insights?.store?.id,
        bankName: this.insights?.store?.bankName,
        accountNumber: this.insights?.store?.accountNumber,
        ifscCode: this.insights?.store?.ifscCode,
        bhimUpi: this.insights?.store?.bhimUpi
      }
    });
  
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          // Temporarily update local state for immediate feedback
          this.insights.store = {
            ...this.insights.store,
            bankName: result.bank_name,
            accountNumber: result.bank_account,
            ifscCode: result.bank_ifsc,
            bhimUpi: result.bhim_upi
          };
  
          // Trigger change detection for immediate UI update
          this.ChangeDetectorRef.detectChanges();
  
          // Fetch fresh data from server
          this.getInsights();
        }
      },
      error: (error) => {
        console.error('Error updating bank details:', error);
        // Refresh data even on error to ensure consistency
        this.getInsights();
      }
    });
  }

  getTimingForDay(day: string): string {
    if (!this.storedays?.schedule) return '';
    
    const schedule = this.storedays.schedule.find((s: StoreSchedule) => 
      s.day.toLowerCase() === day.toLowerCase()
    );

    if (!schedule) return '08:00 AM - 08:00 PM';
    if (!schedule.isOpen) return 'Closed';
    return `${schedule.openingTime} - ${schedule.closingTime}`;
  }

  onEdit() {
    // Implement edit functionality
    console.log('Edit clicked');
  }

  createChart(dataSet1: any, dataset2: any = null, labels: string[]) {
    this.chart = new Chart('MyChart', {
      type: 'line', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: labels,
        datasets: [
          {
            label: dataSet1.label,
            data: dataSet1.data,
            backgroundColor: 'rgba(0, 0, 255, 0.2)',
            borderColor: 'rgba(101, 186, 13, 1)',

            fill: false,
          },
          {
            label: dataset2?.label,
            data: dataset2?.data,
            backgroundColor: 'rgba(240, 170, 3, 0.7)',
            borderColor: 'rgba(240, 170, 3, 0.7)',

            borderDash: [10, 5],
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        aspectRatio: 1,
        elements: {
          line: {
            tension: 0.4, // controls the curve of the line
            borderWidth: 2,
          },
          point: {
            radius: 3, // set the radius to 0 to remove the dots from the line chart
          },
        },
      },
    });
  }
  openModal(type: number) {
    const modal = this.dialogRef.open(StoreRunnerComponent, {
      data: {
        type,
        currentStoreId: this.storeId ,
      },
    });
    modal.componentInstance.trigger.subscribe((data) => {
      this.getRunners();
    });
  }
  openEditModal(profile: any) {
    console.log(profile);
    const modal = this.dialogRef.open(StoreRunnerComponent, {
      data: {
        type: 2,
        profile,
        currentStoreId: this.storeId ,
      },
    });
    modal.componentInstance.trigger.subscribe(() => {
      this.getRunners();
    });
  }
  constructor(
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialog,
    private ChangeDetectorRef: ChangeDetectorRef
  ) {
    this.month = new Date(Date.now()).getMonth() + 1;
    this.year = new Date(Date.now()).getFullYear();
  }
  updateChart() {
    this.chart.update();
  }
  changeChart(value: number) {
    if (value === 1) {
      this.currentChart = 1;
      this.getChartData();
    } else if (value === 2) {
      this.currentChart = 2;
      this.getYearlyData();
    }
  }
  ngOnInit(): void {
    this.getChartData();
    this.getDaySales();
    this.getInsights();
    this.getRunners();
    this.getStoreDays();
  }
  updateMonth(type: number) {
    if (type === 1) {
      if (this.month > new Date(Date.now()).getMonth()) {
        return;
      }
      this.month++;
    } else if (type === 2) {
      if (this.month < 2) {
        return;
      }
      this.month -= 1;
    }
    this.getChartData();
  }

  getChartData() {
    this.http
      .get(
        `${environment.SERVER_URL}stores/monthlygraph?franchise=${environment.FRANCHISE}&month=${this.month}&year=${this.year}`,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        const labels = data.data.currentMonth.map((el: any) => {
          return el.date.slice(-2);
        });
        this.currentMonthGraphLabels = labels;

        const currentMonthData = data.data.currentMonth.map(
          (e: any) => e.amount
        );
        const previousMonthGraphData = data.data.previousMonth.map(
          (el: any) => el.amount
        );
        this.chart?.destroy();
        this.createChart(
          { label: 'Current Month', data: currentMonthData },
          { label: 'Previous Month', data: previousMonthGraphData },
          labels
        );
      });
  }
  getDaySales() {
    this.http
      .get(`${environment.SERVER_URL}stores/daySales?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.dailySales = data.data[0];
        this.dailySalesCalc();
      });
  }
  getYearlyData() {
    this.http
      .get(`${environment.SERVER_URL}stores/yearlyGraph?franchise=${environment.FRANCHISE}&year=${this.year}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        const labels = data.data.map((e: any) => this.months[e.month]);
        const values = data.data.map((e: any) => e.amount);

        this.chart?.destroy();
        this.createChart({ label: this.year, data: values }, null, labels);
      });
  }
}

