import { Component, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-directory-search',
  templateUrl: './customer-directory-search.component.html',
  styleUrls: ['./customer-directory-search.component.scss']
})
export class CustomerDirectorySearchComponent {
  @Output() searchResults = new EventEmitter<any>();
  @Output() totalPagesUpdate = new EventEmitter<number>();

  private currentPage = 1;
  private sort = '';
  private search = '';

  constructor(
    private http: HttpClient,
    private utils: UtilityService
  ) {}

  handleSearch(event: any) {
    const searchTerm = event.target.value.trim();
    this.currentPage = 1;

    if (searchTerm.length === 0) {
      this.getMethod();
      return;
    }

    this.search = searchTerm;
    this.getusersMobile();
  }

  private getusersMobile() {
    this.http.get(
      `${environment.SERVER_URL}webuser/searchUserMobile`, 
      {
        params: {
          franchise: environment.FRANCHISE,
          mobile: this.search,
          sort: this.sort,
          page: this.currentPage.toString() // Convert to string
        },
        headers: {
          Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
        }
      }
    ).subscribe({
      next: (data: any) => {
        this.searchResults.emit(data.data);
        this.totalPagesUpdate.emit(data.totalPages);
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error fetching users:', error);
        this.searchResults.emit([]);
        this.totalPagesUpdate.emit(0);
      }
    });
  }

  private getMethod() {
    this.http.get(
      `${environment.SERVER_URL}webuser/getusers`, 
      {
        params: {
          franchise: environment.FRANCHISE,
          page: this.currentPage.toString(), // Convert to string
          sort: this.sort
        },
        headers: {
          Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
        }
      }
    ).subscribe({
      next: (data: any) => {
        this.searchResults.emit(data.data);
        this.totalPagesUpdate.emit(data.totalPages);
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error loading users:', error);
        this.searchResults.emit([]);
        this.totalPagesUpdate.emit(0);
      }
    });
  }

  setSort(sortValue: string) {
    this.sort = sortValue;
    this.handleSearch({ target: { value: this.search } });
  }

  setPage(page: number) {
    this.currentPage = page;
    this.handleSearch({ target: { value: this.search } });
  }
}