<div class="main-container">
  <div class="left-container">
    <div class="left-header">
      <div class="left-header-main">
        <div class="basic-information-container" style="margin-right: 20px; border-radius: 25px; padding: 20px; ">
          <div class="header">
            <b>BASIC INFORMATION</b>
          </div>
          <hr />
          <div class="basic-infromation-container">
            <div class="flex-box">
              <div class="basic-information-item">
                <b>STORE CODE</b>
                <div>{{ insights?.store.storecode }}</div>
              </div>
              <br />
              <div class="basic-information-item">
                <b>MOBILE NUMBER</b>
                <div>{{ insights?.store.mobile }}</div>
              </div>
            </div>
            <br />
            <div class="basic-information-item">
              <b>STORE NAME</b>
              <div>{{ insights?.store.name }}</div>
            </div>
            <br />
            <hr />
            <div class="basic-information-item">
              <b>REG. FRANCHISE NAME</b>
              <div>{{ insights?.store.reg_franchise_name }}</div>
            </div>
            <hr />
            <div class="basic-information-item">
              <b>ADDRESS</b>
              <div>{{ insights?.store.address }}</div>
            </div>
            <hr />
            <div class="flex-box">
              <div class="basic-information-item">
                <b>LATTITUDE</b>
                <div>{{ insights?.store.latitude }}</div>
              </div>
              <div class="basic-information-item">
                <b>LONGITUTDE</b>
                <div>{{ insights?.store.longitude }}</div>
              </div>
              <div class="basic-information-item">
               
                <b>GSTIN NO. </b><span (click)="openEditGstDialog()"><img [src]="edit"/></span>
                <div>{{ insights?.store.gst ? insights?.store.gst : "-" }}</div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="basic-information-container"  style="margin-right: 20px;   background-color: transparent !important;"> 
          <div class="basic-information-container"  style="margin-bottom: 20px; border-radius: 25px; padding: 20px;">
          <div class="header">
            <b>BANK AND ADDITIONAL INFORMATION</b>
            <div (click)="openBankDetailsDialog()"> Bank Details 

              <img [src]="edit"/>
            </div>
           
          </div>
          <hr />
          <div class="basic-infromation-container">
            <div class="flex-box">
              <div class="basic-information-item">
                <b>BANK NAME</b>
                <div>
                  {{
                    insights?.store.bank_name ? insights?.store.bank_name : "-"
                  }}
                </div>
              </div>

              <div class="basic-information-item">
                <b>ACCOUNT NUMBER</b>
                <div>
                  {{
                    insights?.store.bank_account
                      ? insights?.store.bank_account
                      : "-"
                  }}
                </div>
              </div>
              <div class="basic-information-item">
                <b>IFSC</b>
                <div>
                  {{
                    insights?.store.bank_ifsc ? insights?.store.bank_ifsc : "-"
                  }}
                </div>
              </div>
            </div>
            <br />
            <div class="flex-box">
              <div class="basic-information-item">
                <b>BHIM UPI</b>
                <div>
                  {{
                    insights?.store.bhim_upi ? insights?.store.bhim_upi : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basic-information-container" style="margin-bottom: 20px; border-radius: 25px; padding: 20px;">
          <div class="header d-flex justify-content-between align-items-center">
            <b>STORE WORKING DAYS & TIMINGS</b>
           
          </div>
          <hr />
          <div class="store-timings-container">
            <div class="timings-grid">
              <ng-container *ngFor="let schedule of storedays">
                <div class="day-timing d-flex justify-content-between align-items-center">
                  <span class="day-label">{{ schedule.day }}</span>
                  <span class="timing">
                    {{ schedule.isOpen ? schedule.openingTime + ' - ' + schedule.closingTime : 'Closed' }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
       
      </div>
    <!-- store-runners.component.html -->
    <div class="left-footer" style="margin-top: 40px; margin-right: 20px; padding: 20px; background-color: white; border-radius: 25px;">
      <div class="header" style="display: flex; justify-content: space-between;">
          <b>STORE RUNNERS DETAILS</b>
          <div class="btn-wrapper" (click)="openModal(1)">
              <button class="btn-primary">Add store runner</button>
          </div>
      </div>
      <hr />
      <div class="row">
          <div class="col-md-12">
              <div class="table-section mt-20">
                  <table>
                      <tr class="header-tr">
                          <td class="header">Runner Name</td>
                          <td class="header">Status</td>
                          <td class="header">Contact details</td>
                          <td class="header">Joining date</td>
                          <td class="header" >Relieving date</td>
                          <td class="header">Actions</td>
                          <td class="header">Activity</td> <!-- New Activity Column -->
                      </tr>
                      <tr *ngFor="let e of runners; let i = index">
                          <td>
                              <div>
                                  <b class="green-text">{{ e?.name }}</b>
                                  <span class="role-text">{{ e?.role }}</span>
                              </div>
                          </td>
                          <td>
                              <span class="txt-align-center"
                                  [ngClass]="{'active-status': e?.status === 1, 'inactive-status': e?.status === 0}">
                                  {{ e?.status === 0 ? "INACTIVE" : "ACTIVE" }}
                              </span>
                          </td>
                          <td>
                              <span class="txt-align-center">{{ e?.mobile }}</span>
                              <span class="txt-align-center email-text">{{ e?.email }}</span>
                          </td>
                          <td>
                              <span class="txt-align-center">{{ e?.created_at | date:'dd-MMM-yyyy' }}</span>
                          </td>
                          <td>
                              <span class="txt-align-center">{{ e?.updated_at |date:'dd-MMM-yyyy' }}</span>
                          </td>
                          <td>
                              <span *ngIf="e?.status === 1" (click)="openEditModal(e)" class="edit-btn">
                                  <i class="edit-icon"></i>
                                  Edit
                              </span>
                          </td>
                           <td>
                             <button class="activity-btn" (click)="openActivityModal(e)">View Activity</button>
                            </td>
                      </tr>
                  </table>
              </div>
          </div>
      </div>
  </div>
  <!-- Modal for Activity -->
  <div *ngIf="showActivityModal" class="modal">
      <div class="modal-content">
          <span class="close" (click)="closeActivityModal()">×</span>
         <h3 style="text-align: center; font-weight: bold;">{{selectedRunner?.name}} Details</h3>
          <hr>
        <div *ngFor="let store of selectedRunner?.stores">
        <div> <b class="bold-text">Store:</b> {{store?.store_name}} </div>
        <div><b class="bold-text">Status: </b> <span [ngClass]="{'active-status': store?.status === 1, 'inactive-status': store?.status === 0}">
                  {{ store?.status === 0 ? "INACTIVE" : "ACTIVE" }}
                </span></div>
          <hr>
        </div>
      </div>
  </div>
    </div>
    <div class="right-container" style="background-color: transparent !important;">
      <div class="sales-box">
        <div class="header">
          <b>STORE STATISTICS</b>
          <hr>
        </div>
      
        <div class="statistics-grid">
          <!-- Month to Date Stats -->
          <div class="stat-group">
            <div class="stat-label">Month To Date Sales ({{ getCurrentMonthName() }})</div>
            <div class="stat-value">
              ₹{{ insights?.monthToDate?.sales || 0 }}
              <span class="percentage" [ngClass]="{'positive': insights?.monthToDate?.percentage > 0, 'negative': insights?.monthToDate?.percentage < 0}">
                ({{ insights?.monthToDate?.percentage > 0 ? '+' : '' }}{{ insights?.monthToDate?.percentage || 0 }}%)
              </span>
            </div>
      
            <div class="stat-label">Month To Date Orders</div>
            <div class="orders-row">
              <span class="order-value">{{ insights?.monthToDate?.orders || 0 }}</span>
              <span class="percentage" [ngClass]="{'positive': insights?.monthToDate?.orderPercentage > 0, 'negative': insights?.monthToDate?.orderPercentage < 0}">
                ({{ insights?.monthToDate?.orderPercentage > 0 ? '+' : '' }}{{ insights?.monthToDate?.orderPercentage || 0 }}%)
              </span>
            </div>
          </div>
      
          <!-- Last Month Stats -->
          <div class="stat-group">
            <div class="stat-label">Last Month ({{ getLastMonthName() }})</div>
            <div class="stat-value">
              ₹{{ insights?.lastMonth?.sales || 0 }}
              <span class="percentage" [ngClass]="{'positive': insights?.lastMonth?.percentage > 0, 'negative': insights?.lastMonth?.percentage < 0}">
                ({{ insights?.lastMonth?.percentage > 0 ? '+' : '' }}{{ insights?.lastMonth?.percentage || 0 }}%)
              </span>
            </div>
      
            <div class="stat-label">Last Month Orders</div>
            <div class="orders-row">
              <span class="order-value">{{ insights?.lastMonth?.orders || 0 }}</span>
              <span class="percentage" [ngClass]="{'positive': insights?.lastMonth?.orderPercentage > 0, 'negative': insights?.lastMonth?.orderPercentage < 0}">
                ({{ insights?.lastMonth?.orderPercentage > 0 ? '+' : '' }}{{ insights?.lastMonth?.orderPercentage || 0 }}%)
              </span>
            </div>
          </div>
      
          <!-- Last Year Same Month Stats -->
          <div class="stat-group">
            <div class="stat-label">Last Year {{ getCurrentMonthName() }}</div>
            <div class="stat-value">
              ₹{{ insights?.lastYear?.sales || 0 }}
              <span class="percentage" [ngClass]="{'positive': insights?.lastYear?.percentage > 0, 'negative': insights?.lastYear?.percentage < 0}">
                ({{ insights?.lastYear?.percentage > 0 ? '+' : '' }}{{ insights?.lastYear?.percentage || 0 }}%)
              </span>
            </div>
      
            <div class="stat-label">Last Year Orders</div>
            <div class="orders-row">
              <span class="order-value">{{ insights?.lastYear?.orders || 0 }}</span>
              <span class="percentage" [ngClass]="{'positive': insights?.lastYear?.orderPercentage > 0, 'negative': insights?.lastYear?.orderPercentage < 0}">
                ({{ insights?.lastYear?.orderPercentage > 0 ? '+' : '' }}{{ insights?.lastYear?.orderPercentage || 0 }}%)
              </span>
            </div>
          </div>
        </div>
      </div>
    
      <br />
    
      <div class="sales-main-container">
        <div class="sales-btn-container">
          <div [ngClass]="{ active: currentChart === 1 }" (click)="changeChart(1)">
           Monthly Sales
          </div>
          <div [ngClass]="{ active: currentChart === 2 }" (click)="changeChart(2)">
          Yearly Sales
          </div>
        </div>
      </div>
    
      <br />
    
      <div class="chart-container">
        <div class="chart-container-header">
          <div class="chart-container-left">
            {{ currentChart === 1 ? 'LAST MONTH SALES' : 'LAST YEAR SALES' }}
          </div>
          <div class="chart-container-right">
            <div>{{ year }} {{ months[month] }}</div>
            <div class="pointer" (click)="updateMonth(2)">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div>
            <div class="pointer" (click)="updateMonth(1)">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
    
        <br />
    
        <div class="chart-container-total-sales" *ngIf="currentChart == 1">
          <div class="total-sales-container">
            <b>{{ insights?.lastMonth?.sales || 0 }}</b>rs
            <div>per month</div>
          </div>
          <div class="category-wise-container">
            <div class="header">
              <b>Sales On {{ currentDate | date }}</b>
              <b>{{ totalSales }}</b>
            </div>
            <br />
            <div class="category-conatiner">
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/drycleaning.svg"
                  />
                  <div>
                    Dry cleaning -
                    {{ dailySales?.Drycleaning ? dailySales?.Drycleaning : 0 }}
                  </div>
                </div>
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/steamIron.svg"
                  />
                  <div>
                    Steam Iron -
                    {{
                      dailySales?.Standard_Iron ? dailySales?.Standard_Iron : 0
                    }}
                  </div>
                </div>
              </div>
              <br />
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/washAndI.svg"
                  />
                  <div>
                    Wash and Iron -
                    {{ dailySales?.Wash_N_Iron ? dailySales?.Wash_N_Iron : 0 }}
                  </div>
                </div>
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/drycleaning.svg"
                  />
                  <div>
                    Wash & Fold -
                    {{ dailySales?.Wash_N_Fold ? dailySales?.Wash_N_Fold : 0 }}
                  </div>
                </div>
              </div>
              <br />
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{ assets }}../../assets/images/icons/category/pl.svg"
                  />
                  <div>
                    Premium laundry -
                    {{
                      dailySales?.Premium_Laundry
                        ? dailySales?.Premium_Laundry
                        : 0
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="chart" *ngIf="currentChart == 1 || currentChart == 2">
          <canvas id="MyChart" width="400" height="600">{{ chart }}</canvas>
        </div>
      </div>
    </div>
  </div>
</div>
