import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {UtilityService} from '../service/utility.service';
interface FilterState {
  creationDate: string;
  startDate: string;
  endDate: string;
  discountType: string;
  order_no: string;
  status: string;
  channel_type: string;
  franchise?: string;
  creationDateRange?: DateRange;
  startDateRange?: DateRange;
  endDateRange?: DateRange;
}
export interface DateRange {
  start: string | null;
  end: string | null;
}
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient,  private Utils: UtilityService) { }
  // private methods
  private getBaseUrl = () => {
    const url = environment.SERVER_URL;
    return url.replace('/api/v1/', '');
  }
  getCustomerDate(params: {
    dateOption: string;
    applyOn: string;
    fromDate?: string;
    toDate?: string;
  }): Observable<any> {
    const headers = this.initAuthHeaders();
    let url = `${this.getBaseUrl()}/api/v1/webuser/getCustomerDate?franchise=${environment.FRANCHISE}&dateOption=${params.dateOption}&applyOn=${params.applyOn}`;
  
    // Ensure dates are in DD/MM/YYYY format when dateOption is 'Custom'
    if (params.dateOption === 'Custom') {
      if (!params.fromDate || !params.toDate) {
        throw new Error('Custom date range requires both fromDate and toDate');
      }
  
      // Convert Angular's date input (YYYY-MM-DD) to DD/MM/YYYY
      const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
  
      const formattedFromDate = formatDate(params.fromDate);
      const formattedToDate = formatDate(params.toDate);
  
      url += `&fromDate=${formattedFromDate}&toDate=${formattedToDate}`;
    }
  
    return this.http.get(url, { headers }).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('API Error:', error);
        return throwError(error);
      })
    );
  }
  
  private initAuthHeaders = () => {
    return new HttpHeaders({
      Authorization: `Bearer ${this.Utils.getLocalStorageItem('token')}`
    });
  }
  private get<T>(url: string): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.get<T>(`${this.getBaseUrl()}${url}`, { headers });
  }

  private post<T>(url: string, body: any): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.post<T>(`${this.getBaseUrl()}${url}`, body, { headers });
  }
  // public methods
  searchCoupons(query: string): Observable<any[]> {
    return this.get(`/api/v1/coupons/searchCoupons?franchise=${environment.FRANCHISE}&discount_code=${query}`)
      .pipe(
        map((response: any) => response.data as any[]) 
      );
  }
  

  getCoupons(): Observable<any> {
    return this.get(`/api/v1/coupons?franchise=${environment.FRANCHISE}`);
  }
  getFilteredCoupons(filters: FilterState): Observable<any> {
    let queryString = `/api/v1/couponsquery?franchise=${environment.FRANCHISE}`;
  
    // Handle creation date range
    if (filters.creationDateRange?.start && filters.creationDateRange?.end) {
      queryString += `&created_at_start=${filters.creationDateRange.start}&created_at_end=${filters.creationDateRange.end}`;
    }
  
    // Handle start date range
    if (filters.startDateRange?.start && filters.startDateRange?.end) {
      queryString += `&start_date_from=${filters.startDateRange.start}&start_date_to=${filters.startDateRange.end}`;
    }
  
    // Handle end date range
    if (filters.endDateRange?.start && filters.endDateRange?.end) {
      queryString += `&end_date_from=${filters.endDateRange.start}&end_date_to=${filters.endDateRange.end}`;
    }
  
    // Add other filters
    if (filters.discountType) {
      queryString += `&discountType=${filters.discountType}`;
    }
  
    if (filters.order_no) {
      queryString += `&order_no=${filters.order_no}`;
    }
  
    if (filters.status) {
      queryString += `&status=${filters.status}`;
    }
  
    if (filters.channel_type) {
      queryString += `&channel_type=${filters.channel_type}`;
    }
  
    console.log('Sending request to:', this.getBaseUrl() + queryString);
      
    return this.get(queryString).pipe(
      map((response: any) => {
        console.log('API Response:', response);
        return response;
      })
    );
  }

  generateGST(fromDate: string, toDate: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.post(
      `/api/v1/stores/generateGST?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }

  generateDSR(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateDSR?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateCollectionReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateCollectionReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateQuantityReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateQuantityReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  


  submitAddress(address: any): Observable<any> {
    return this.post(`/api/v1/address?franchise=${environment.FRANCHISE}`, address);
  }
  updateBookingService(serviceId: string, serviceData: any): Observable<any> {
    return this.post(`/api/v1/booking_services/${serviceId}?franchise=${environment.FRANCHISE}`, serviceData);
  }
  getOrderthroughs(): Observable<any> {
    return this.get(`/api/v1/stores/orderthroughs?franchise=${environment.FRANCHISE}`);
  }
  updateOrderStatus(bookingId: string | number, status: number): Observable<any> {
  return this.post(`/api/v1/bookings/updateStatus/${bookingId}?franchise=${environment.FRANCHISE}`, { status });
  }
  processPayment(bookingId: string | number, payload: any): Observable<any> {
    return this.post(`/api/v1/stores/bookings/processPayment/${bookingId}?franchise=${environment.FRANCHISE}`, payload);
  }
  generateBarcode(bookingId: number): Observable<any> {
    return this.get(`/api/v1/bookings/generateBarCode/${bookingId}?franchise=${environment.FRANCHISE}`);
  }
  updateMultipleBookings(bookings: any[], status: number): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.post(
      `${environment.SERVER_URL}bookings/updateMultiple`,
      { bookings, status },
      { headers }
    );
  }

  genericPostRequest(url: string, body: any): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.post(`${environment.SERVER_URL}${url}`, body, { headers });
  }
  searchBooking(searchQuery: string, type: string, sortQuery: string, currentPage: number, selectedFilter: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.get(
      `${environment.SERVER_URL}bookings/search?query=${searchQuery}&type=${type}&sortQuery=${sortQuery}&page=${currentPage}&filterQuery=${selectedFilter}`,
      { headers }
    );
  }

  getBookings(currentPage: number, type: string, sortQuery: string, selectedFilter: string, dateS: string, fromDate: string, toDate: string,orderThroughFilter: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.get(
      `${environment.SERVER_URL}bookings?page=${currentPage}&type=${type}&sortQuery=${sortQuery}&filterQuery=${selectedFilter}&sortDate=${dateS}&sortFrom=${fromDate}&sortTo=${toDate}${orderThroughFilter}`,
      { headers }
    );
  }
  
}
