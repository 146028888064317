import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
import { DropCustomerReportComponent } from './reports/drop-customer-report/drop-customer-report.component';
import { CustomerDirectoryComponent } from './feature/dashboard/customer-directory/customer-directory.component';
import { CustomerOrderListingComponent } from './feature/dashboard/customer-order-listing/customer-order-listing.component';
import { InvoiceContainerComponent } from './feature/invoice/invoice-container/invoice-container.component';
import { OrderFlowStep1Component } from './feature/order/order-flow-step1/order-flow-step1.component';
import { OrderFlowStep2Component } from './feature/order/order-flow-step2/order-flow-step2.component';
import { OrderFlowStep3Component } from './feature/order/order-flow-step3/order-flow-step3.component';
import { OrderFlowWrapperComponent } from './feature/order/order-flow-wrapper/order-flow-wrapper.component';
import { LoginComponent } from './login/login.component';
import { DailySalesReportComponent } from './reports/daily-sales-report/daily-sales-report.component';
// import { DropCustomerReportComponent } from './reports/drop-customer-report/drop-customer-report.component';
import { GstReportComponent } from './reports/gst-report/gst-report.component';
import { MonthWiseCategoryReportComponent } from './reports/month-wise-category-report/month-wise-category-report.component';
import { CancelledOrdersReportsComponent } from './reports/cancelled-orders-reports/cancelled-orders-reports.component';
import { PackageTableComponent } from './package-table/package-table.component';
import { CouponsTableComponent } from './coupons-table/coupons-table.component';

import { AddCouponStep1Component } from './add-coupon/add-coupon-step1/add-coupon-step1.component';
import { AddCouponStep2Component } from './add-coupon/add-coupon-step2/add-coupon-step2.component';
import { AddCouponStep3Component } from './add-coupon/add-coupon-step3/add-coupon-step3.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { ArchiveComponent } from './archive/archive.component';
import { RoutingComponent } from './routing/routing.component';
import { StoreProfileComponent } from './store-profile/store-profile.component';
import { PricingComponent } from './pricing/pricing.component';
import { AddCouponStep4Component } from './add-coupon/add-coupon-step4/add-coupon-step4.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'invoice-flow/:id', component: InvoiceContainerComponent },
  { path: 'price', component: PricingComponent },
  {
    path: 'order-flow',
    component: OrderFlowWrapperComponent,
    children: [
      { path: '', redirectTo: 'step1', pathMatch: 'full' },
      { path: 'step1', component: OrderFlowStep1Component },
      { path: 'step2', component: OrderFlowStep2Component },
      { path: 'step3/:id', component: OrderFlowStep3Component },
    ],
  },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/user/:id', component: OrderFlowStep3Component },
  { path: 'customer-directory', component: CustomerDirectoryComponent },
  { path: 'login', component: LoginComponent },
  { path: 'add-coupons', component: AddCouponComponent },
  { path: 'daily-sales-report', component: DailySalesReportComponent },
  { path: 'gst-report', component: GstReportComponent },
  { path: 'drop-customer-report', component: DropCustomerReportComponent },
  { path: 'packages-table', component: PackageTableComponent },
  { path: 'coupons-table', component: CouponsTableComponent },
  { path: 'add-coupons-step-1', component: AddCouponStep1Component },
  { path: 'add-coupons-step-2', component: AddCouponStep2Component },
  { path: 'add-coupons-step-3', component: AddCouponStep3Component },
  { path: 'add-coupons-step-4', component: AddCouponStep4Component },
  { path: 'archive', component: ArchiveComponent },
  { path: 'barcode/:id', component: BarcodeComponent },
  { path: 'profile', component: StoreProfileComponent },
  {
    path: 'testlaundry-and-dry-cleaners-in-marathahalli-bangalore-uc011',
    component: RoutingComponent,
  },

  {
    path: 'cancelled-orders-report',
    component: CancelledOrdersReportsComponent,
  },
  {
    path: 'month-wise-cat-report',
    component: MonthWiseCategoryReportComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
