import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-order-source',
  templateUrl: './order-source.component.html',
  styleUrls: ['./order-source.component.scss']
})
export class OrderSourceComponent implements OnInit {
  sources: string[] = ['Walk-In', 'Store', 'Whatsapp', 'Customer Care', 'Mobile App', 'Website'];
  selectedSources: string[] = [];
  isOpen: boolean = false;
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;

  @Input() type: 'NEW' | 'INPRO' = 'NEW';
  @Output() sourcesChanged = new EventEmitter<string[]>();

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  toggleSource(source: string): void {
    const index = this.selectedSources.indexOf(source);
    if (index === -1) {
      this.selectedSources.push(source);
    } else {
      this.selectedSources.splice(index, 1);
    }
    this.sourcesChanged.emit(this.selectedSources);
  
  }

  
}