import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-edit-gst-dialog',
  templateUrl: './edit-gst-dialog.component.html',
  styleUrls: ['./edit-gst-dialog.component.scss'],
})
export class EditGstDialogComponent {
  gstNumber: string = '';
  isSubmitting: boolean = false;
  validationErrors: string[] = [];
  
  // Valid state codes for GST
  private readonly validStateCodes = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38'
  ];

  constructor(
    private dialogRef: MatDialogRef<EditGstDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { storeId: number, currentGst: string },
    private http: HttpClient,
    private utils: UtilityService
  ) {
    this.gstNumber = data.currentGst || '';
  }

  validateGST(): boolean {
    this.validationErrors = [];
    const gst = this.gstNumber.trim().toUpperCase();

    // Check length
    if (gst.length !== 15) {
      this.validationErrors.push(`GST number must be exactly 15 characters long. Current length: ${gst.length}`);
      return false;
    }

    // Validate state code
    const stateCode = gst.substring(0, 2);
    if (!this.validStateCodes.includes(stateCode)) {
      this.validationErrors.push(`Invalid state code '${stateCode}'. First two digits must be a valid state code.`);
      return false;
    }

    // Validate PAN format with detailed errors
    const panPortion = gst.substring(2, 12);
    const firstFiveChars = panPortion.substring(0, 5);
    const middleFourChars = panPortion.substring(5, 9);
    const lastChar = panPortion.substring(9, 10);

    if (!/^[A-Z]{5}$/.test(firstFiveChars)) {
      this.validationErrors.push(`First 5 characters of PAN (${firstFiveChars}) must be uppercase letters A-Z`);
      return false;
    }

    if (!/^[0-9]{4}$/.test(middleFourChars)) {
      this.validationErrors.push(`Middle 4 characters of PAN (${middleFourChars}) must be numbers 0-9`);
      return false;
    }

    if (!/^[A-Z]$/.test(lastChar)) {
      this.validationErrors.push(`Last character of PAN (${lastChar}) must be an uppercase letter A-Z`);
      return false;
    }

    // Validate entity number
    const entityNumber = gst.charAt(12);
    if (!/^[1-9A-Z]$/.test(entityNumber)) {
      this.validationErrors.push(`Entity number (${entityNumber}) must be 1-9 or A-Z`);
      return false;
    }

    // Validate Z character
    if (gst.charAt(13) !== 'Z') {
      this.validationErrors.push('The 14th character must be Z');
      return false;
    }

    // Validate checksum
    const checksum = gst.charAt(14);
    if (!/^[0-9A-Z]$/.test(checksum)) {
      this.validationErrors.push(`Checksum (${checksum}) must be 0-9 or A-Z`);
      return false;
    }

    return true;
  }

  onGSTInput(): void {
    // Format GST number to uppercase and remove spaces
    this.gstNumber = this.gstNumber.trim().toUpperCase();
    // Validate on each input
    this.validateGST();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.validateGST()) {
      return;
    }

    this.isSubmitting = true;
    
    const payload = {
      id: this.data.storeId,
      gst: this.gstNumber.trim()
    };

    this.http.put(
      `${environment.SERVER_URL}stores/updategst`,
      payload,
      {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
        }
      }
    ).subscribe({
      next: (response: any) => {
        this.dialogRef.close(this.gstNumber);
      },
      error: (error) => {
        console.error('Error updating GST:', error);
        this.isSubmitting = false;
        this.validationErrors = ['Failed to update GST number. Please try again.'];
      }
    });
  }
}