 
 <div [ngClass]="{'uclean': currentBrand === 'uclean'}">
    <div class="page-bottom-text">
       Showing {{ (currentPage - 1) * 30 }} to
       {{ orders?.length < 30 ? orders?.length : currentPage * 30 }} out of
       {{ totalPages * 30 }} entries
     </div><div class="pagination">
    
   
     <div class="pagination-controls">
       <button [disabled]="currentPage === 1" class="btn-txt" (click)="onPrevious()">Prev</button>
   
       <div class="pagination-page-number">
         <div *ngFor="let page of getPageNumbers(); let i = index">
           <div
             class="page-number"
             [ngClass]="{ active: currentPage === page }"
             (click)="updateCurrentPage(page)"
             *ngIf="i < 5 || page === -1"
           >
             {{ page === -1 ? "..." : page }}
           </div>
         </div>
       </div>
   
       <div
         class="page-number"
         (click)="updateCurrentPage(totalPages)"
         *ngIf="totalPages > 5"
         [ngClass]="{ active: currentPage === totalPages }"
       >
         {{ totalPages }}
       </div>
   
       <button [disabled]="currentPage === totalPages" class="btn-txt" (click)="onNext()">Next</button>
     </div>
   </div>
   </div>