<div class="dropdown-container" appClickOutside (clickOutside)="closeDropdown()">
  <button class="dropdown-box" (click)="toggleDropdown()">
    <div class="button-content">
      <span class="text-content">{{ displayText }}</span>
      <img [src]="dropIcon" alt="dropdown-icon" />
    </div>
  </button>
  <div *ngIf="isOpen" class="dropdown-content">
    <label *ngFor="let source of sources" 
           [class.selected]="selectedSources.includes(source)">
      <input type="checkbox"
             [checked]="selectedSources.includes(source)"
             (change)="toggleSource(source)">
      <span class="checkmark"></span>
      {{ source }}
    </label>
  </div>
</div>