import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';
import { CancelModalComponent } from '../cancel-modal/cancel-modal.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-order-ready-modal',
  templateUrl: './order-ready-modal.component.html',
  styleUrls: ['./order-ready-modal.component.scss']
})
export class OrderReadyModalComponent implements OnInit {

  @Output() trigger = new EventEmitter<any>();
  public message: any = '';
  public messageType: number = -1;
  public messageToDisplay: string = '';
  public postURL: string;
  public bookingId: number;
  public body: any;
  public delete: number = 0;
  public reasons: string[];
  public reason: string = '';
  @Input() logout: boolean = false;
  ngOnInit(): void {}
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialogRef<CancelModalComponent>,
    private router: Router,
    private apiService: ApiService
  ) {
    this.postURL = data?.postURL;
    this.bookingId = data?.bookingId;
    this.messageToDisplay = data?.messageToDisplay;
    this.body = data.body;
    this.delete = data?.delete;
    // console.log(data)
    this.logout = data?.logout;
    this.reasons = ['Estimated delivery was too long', 'Reason 2 ', 'Reason 3'];
  }
  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      false;
    }
    return;
  }
  closeModal() {
    this.dialogRef.close();
  }
  logoutConfirm() {
    this.utils.deleteLocalStorage('token');
    // this.goToUrl('login');
    this.router.navigateByUrl('login');
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }

  handlePost() {
    if (this.logout) {
      this.logoutConfirm();
      return;
    }

    this.message = '';
    console.log(this.postURL);

    if (this.delete) {
      this.body.reason = this.reason;
    }

    if (this.body.status === 4 && Array.isArray(this.bookingId)) {
      this.apiService.updateMultipleBookings(this.bookingId, this.body.status)
        .subscribe(
          this.handleSuccess.bind(this),
          this.handleError.bind(this)
        );
    } else {
      this.apiService.genericPostRequest(this.postURL, this.body)
        .subscribe(
          this.handleSuccess.bind(this),
          this.handleError.bind(this)
        );
    }
  }

  private handleSuccess(data: any) {
    this.trigger.emit({ success: true });
    console.log(data.data);
    this.message = data.message;
    this.messageType = 0;
    setTimeout(() => {
      this.closeModal();
    }, 2000);
  }

  private handleError(err: any) {
    console.log({ err });
    console.log({ msg: err.error.message });
    this.message = err.error.message;
    this.messageType = 1;
  }

}