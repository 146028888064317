import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {UtilityService} from '../service/utility.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient,  private Utils: UtilityService) { }
  // private methods
  private getBaseUrl = () => {
    const url = environment.SERVER_URL;
    return url.replace('/api/v1/', '');
  }
  private initAuthHeaders = () => {
    return new HttpHeaders({
      Authorization: `Bearer ${this.Utils.getLocalStorageItem('token')}`
    });
  }
  private get<T>(url: string): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.get<T>(`${this.getBaseUrl()}${url}`, { headers });
  }

  private post<T>(url: string, body: any): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.post<T>(`${this.getBaseUrl()}${url}`, body, { headers });
  }
  // public methods
  searchCoupons(query: string): Observable<any[]> {
    return this.get(`/api/v1/coupons/searchCoupons?franchise=${environment.FRANCHISE}&discount_code=${query}`)
      .pipe(
        map((response: any) => response.data as any[]) 
      );
  }
  

  getCoupons(): Observable<any> {
    return this.get(`/api/v1/coupons?franchise=${environment.FRANCHISE}`);
  }
  generateGST(fromDate: string, toDate: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.post(
      `/api/v1/stores/generateGST?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }

  generateDSR(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateDSR?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateCollectionReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateCollectionReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateQuantityReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateQuantityReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }


  submitAddress(address: any): Observable<any> {
    return this.post(`/api/v1/address?franchise=${environment.FRANCHISE}`, address);
  }
  updateBookingService(serviceId: string, serviceData: any): Observable<any> {
    return this.post(`/api/v1/booking_services/${serviceId}?franchise=${environment.FRANCHISE}`, serviceData);
  }
  getOrderthroughs(): Observable<any> {
    return this.get(`/api/v1/stores/orderthroughs?franchise=${environment.FRANCHISE}`);
  }
  updateOrderStatus(bookingId: string | number, status: number): Observable<any> {
  return this.post(`/api/v1/bookings/updateStatus/${bookingId}?franchise=${environment.FRANCHISE}`, { status });
  }
  processPayment(bookingId: string | number, payload: any): Observable<any> {
    return this.post(`/api/v1/stores/bookings/processPayment/${bookingId}?franchise=${environment.FRANCHISE}`, payload);
  }
  generateBarcode(bookingId: number): Observable<any> {
    return this.get(`/api/v1/bookings/generateBarCode/${bookingId}?franchise=${environment.FRANCHISE}`);
  }
  updateMultipleBookings(bookings: any[], status: number): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.post(
      `${environment.SERVER_URL}bookings/updateMultiple`,
      { bookings, status },
      { headers }
    );
  }

  genericPostRequest(url: string, body: any): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.post(`${environment.SERVER_URL}${url}`, body, { headers });
  }
  searchBooking(searchQuery: string, type: string, sortQuery: string, currentPage: number, selectedFilter: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.get(
      `${environment.SERVER_URL}bookings/search?query=${searchQuery}&type=${type}&sortQuery=${sortQuery}&page=${currentPage}&filterQuery=${selectedFilter}`,
      { headers }
    );
  }

  getBookings(currentPage: number, type: string, sortQuery: string, selectedFilter: string, dateS: string, fromDate: string, toDate: string,orderThroughFilter: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.http.get(
      `${environment.SERVER_URL}bookings?page=${currentPage}&type=${type}&sortQuery=${sortQuery}&filterQuery=${selectedFilter}&sortDate=${dateS}&sortFrom=${fromDate}&sortTo=${toDate}${orderThroughFilter}`,
      { headers }
    );
  }
  
}
