import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;

  constructor() { }

  ngOnInit(): void {
  }

}