<div class="date-range-container" appClickOutside (clickOutside)="closeDropdown()">
  <div class="dropdown-toggle" (click)="toggleDropdown()">
    <span class="text-content">{{ getSelectedOptionsText() }}</span>
    <img [src]="dropdownIcon" alt="dropdown" class="dropdown-icon">
  </div>
  <div *ngIf="isOpen" class="dropdown-content">
    <div *ngIf="type === 'INPRO'" class="apply-on-section">
      <h4 style="padding-left: 2px;">Apply On</h4>
      <label *ngFor="let applyOption of applyOnOptions">
        <input type="checkbox" 
               [value]="applyOption" 
               (change)="onApplyOptionChange(applyOption)" 
               [checked]="selectedApplyOptions.includes(applyOption)">
        <span class="checkmark"></span>
        {{ applyOption }}
      </label>
      <hr>
    </div>
    <label *ngFor="let option of dateOptions" [class.selected]="selectedOptions.includes(option)">
      <input type="checkbox" 
             [value]="option" 
             (change)="onOptionChange(option)" 
             [checked]="selectedOptions.includes(option)">
      <span class="checkmark"></span>
      {{ option }}
    </label>
    <div *ngIf="selectedOptions.includes('Custom')" class="custom-date-range">
      <div class="date-input">
        <label style="padding-left: 0px !important">From</label>
        <input type="date" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate">
      </div>
      <div class="date-input">
        <label style="padding-left: 0px !important">To</label>
        <input type="date" placeholder="DD/MM/YYYY" [(ngModel)]="toDate">
      </div>
      <div class="button-group">
        <button class="save-btn" (click)="saveCustomDate()">SAVE</button>
        <button class="cancel-btn" (click)="cancelCustomDate()">Cancel</button>
      </div>
    </div>
  </div>
</div>