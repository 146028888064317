<section class="order-flow-section" [ngClass]="{'uclean': currentBrand === 'uclean'}">
    <div class="fixed-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="!typeOfMessage(message)">
              <app-notifications
                [message]="message"
                [type]="messageType"
              ></app-notifications>
            </div>
            <div class="container">
                <div class="dashboard-heading"> <app-search
    [orderType]="type"
    (searchPerformed)="onSearchPerformed($event)"
  ></app-search>
                  <app-order-source (sourcesChanged)="onSourcesChanged($event)"></app-order-source>
                  <app-date-range 
                  *ngIf="type === 'NEW'" 
                  [type]="type"
                  (dateRangeChange)="onDateRangeChange($event)">
                </app-date-range>
                
                <app-date-range 
                  *ngIf="type === 'INPRO'" 
                  [type]="type"
                  (dateRangeChange)="onDateRangeChange($event)">
                </app-date-range>
                  <button
                        class="btn primary" style="margin-left: auto"
                        (click)="gotoUrl('order-flow/step1')">
                        + Add Order
                </button>
                </div>
                
            
            </div>
                    
            <div class="group-section">
              <button
                mat-ripple
                class="group-item"
                (click)="
                  isNewOrderListed = true; type = 'NEW'; searchBooking()
                "
                [ngClass]="{ active: isNewOrderListed }"
              >
                New Orders
                <div class="active-indicator"></div>
              </button>
              <button
                mat-ripple
                class="group-item"
                (click)="
                  isNewOrderListed = false;
                  type = 'INPRO';searchBooking();
                "
                [ngClass]="{ active: !isNewOrderListed }"
              >
                In Process
                <div class="active-indicator"></div>
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="table-section">
            <table *ngIf="orders !== null" [ngClass]="type">
              <thead>
                <tr>
                  <th class="order-align">
                    
                        <input type="checkbox" class="order-checkbox" (click)="selectAll()" />
                      <span>Order</span>
                      <span class="sort-icon" (click)="sortAscending('booking_code')">{{ getSortIcon('booking_code') }}</span>
                    
                  </th>
                  <th *ngIf="type === 'NEW'">
                    <span
                      >Pickup Date
                    </span>
                    <span class="sort-icon" (click)="sortAscending('pickup_date')">{{ getSortIcon('pickup_date') }}</span>
                  </th>
                  <th *ngIf="type === 'NEW'">
                    <span
                      >Pickup Time
                      </span>
                      <span class="sort-icon" (click)="sortAscending('pickup_time')">{{ getSortIcon('pickup_time') }}</span>
                  </th>
                  <th class="status-width" *ngIf="type === 'INPRO'">
                    <span>Pickup Status</span>
                    <span class="sort-icon" (click)="sortAscending('pickup_date')">{{ getSortIcon('pickup_date') }}</span>
                  </th>
                  <th class="status-width"  *ngIf="type === 'INPRO'">
                    <span>Delivery Status</span>
                    <span class="sort-icon" (click)="sortAscending('delivery_date')">{{ getSortIcon('delivery_date') }}</span>
                  </th>
                  <th *ngIf="type === 'NEW'">
                    <span>Customer</span>
                  </th>
                  <th class="custom-width" *ngIf="type === 'NEW'">
                    <span>Address</span>
                  </th>
                  <th class="custom-width"  *ngIf="type === 'NEW'">
                    <span>Instructions</span>
                  </th>
                  <th *ngIf="type === 'INPRO'">
                    <span>Invoice</span>
                  </th>
                  <th *ngIf="type === 'INPRO'">
                    <span>Order ready</span>
                  </th>
                  <th *ngIf="type === 'INPRO'">
                    <span>Assign delivery</span>
                  </th>
                  <th *ngIf="type === 'INPRO'">
                    <span>Delivery done</span>
                  </th>
                  <th class="action-width" *ngIf="type === 'NEW'">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let orderObj of orders; let i = index"
                  [ngClass]="{
                    active: multipleBookings.includes(orderObj),
                    'order-date-passed': convertToDate(orderObj?.pickup_date)
                  }"
                >
                  <td>
                    <input
                      type="checkbox"
                      class="order-checkbox"
                      [checked]="isSelectedInMultiple(orderObj.id)"
                      (change)="handleAddOrRemoveFromBookings($event, orderObj)"
                    />
                    <span>
                      <img
                        [src]="renderIcons(orderObj.orderthrough_id)"
                        alt="Order icon"
                      />
                      <span class="booking-code">{{ orderObj?.booking_code }}</span>
                      
                    </span>
                    <ng-container *ngIf="type === 'INPRO'">
                        <div class="info"><div style="font-weight: 600;">
                        
                        {{ orderObj?.name }}
                      </div>
                      <div>
                        
                        {{ orderObj?.mobile }}
                      </div>
                      <div>
                       
                        <app-address
                          [address]="formatAddress(orderObj?.address)"
                        ></app-address>
                      </div></div>
                      
                    </ng-container>
                  </td>
                  <td *ngIf="type === 'NEW'">
                    {{ orderObj?.created_at | date : "yyyy-MM-dd" }}
                  </td>
                  <td *ngIf="type === 'NEW'">
                    {{ orderObj?.pickup_time || "" }}
                  </td>
                  <td *ngIf="type === 'INPRO'">
                    <div>{{ orderObj.pickup_date }}</div>
                    <div>{{ orderObj.pickup_time }}</div>
                  </td>
                  <td *ngIf="type === 'INPRO'">
                    <div>{{ orderObj.delivery_date }}</div>
                    <div>{{ orderObj?.delivery_time }}</div>
                  </td>
                  <td *ngIf="type === 'NEW'">
                    <div class="name">{{ orderObj?.name }}</div>
                    <div class="darkGreen">{{ orderObj?.mobile }}</div>
                  </td>
                  <td class="custom-width" *ngIf="type === 'NEW'">
                    <app-address
                      [address]="formatAddress(orderObj?.address)"
                    ></app-address>
                  </td>
                  <td class="custom-width address-wrapper" *ngIf="type === 'NEW'">
                    <app-address
                      *ngIf="orderObj?.suggestions?.length !== 0"
                      [address]="orderObj?.suggestions"
                    ></app-address>
                    <span *ngIf="orderObj?.suggestions?.length === 0">NA</span>
                  </td>
                  <!-- Add more cells for INPRO type here -->
                  <td *ngIf="type === 'NEW'">
                    <div style="display: flex;"> <button
                    *ngIf="orderObj?.status === 1"
                            class="btn primary"
                            (click)="assignPickup(orderObj.id)">
                      Assign Pickup
                    </button>
                    <img
                    
                    src="../../assets/images/cross.svg"
                      class="delete"
                      style="margin-left: 20px !important"
                      (click)="handleCancel(orderObj.id)"
                      alt="Cancel"
                    /></div>
                   
                  </td>
                  <td class="order-progress" *ngIf="type === 'INPRO'" colspan="4">
                    <div class="progress-container">
                      <div
                        class="progress-step"
                        [ngClass]="getStepClass(orderObj.status, 2)"
                      >
                        <ng-container
                          *ngIf="orderObj.status <= 2; else invoiceGenerated"
                        >
                          <button
                            class="btn primary"
                            (click)="gotoUrl('invoice-flow/' + orderObj.id)"
                          >
                            Generate
                          </button>
                        </ng-container>
                        <ng-template #invoiceGenerated>
                            <div class="completed-step btn primary " style="background-color: rgba(101, 186, 13, 0.1) !important; box-shadow: none !important; display: flex !important;">
                                <div style="display: flex;">
                                
                                <div
                                  class="view-invoice"
                                  (click)="viewInvoice(orderObj?.id)"
                                >{{ orderObj?.invoice_id }}</div><img
                                  (click)="printBarCode(orderObj.id)"
                                  src="../../assets/images/icons/qrcode.svg"
                                  style="height: 30px; width: 30px;"
                                  alt="QR Code"
                                /></div>
                                
                                
                                <div class="timestamp">
                                  {{ displayTime(orderObj?.in_process_at) }}
                                </div>
                              </div>
                        </ng-template>
                      </div>
  
                      <div
                        class="progress-step"
                        [ngClass]="getStepClass(orderObj.status, 3)"
                      >
                        <button
                          class="btn"
                          [ngClass]="{
                            primary: orderObj.status === 3,
                            disabled: orderObj.status < 3
                          }"
                          (click)="
                            orderObj.status === 3 && updateOrder(orderObj.id)
                          "
                        >
                          {{ orderObj.status > 4 ? "Order Ready" : "Mark Ready" }}
                        </button>
                        <div *ngIf="orderObj.status > 3" class="timestamp">
                          {{ displayTime(orderObj?.order_ready_at) }}
                        </div>
                      </div>
  
                      <div
                        class="progress-step"
                        [ngClass]="getStepClass(orderObj.status, 4)"
                      >
                        <button
                          class="btn"
                          [ngClass]="{
                            primary: orderObj.status === 4,
                            disabled: orderObj.status < 4
                          }"
                          (click)="
                            orderObj.status === 4 && assignDrop(orderObj.id)
                          "
                        >
                          {{
                            orderObj.status > 4 ? "Assigned" : "Assign delivery"
                          }}
                        </button>
                        <div *ngIf="orderObj.status > 4" class="timestamp">
                          {{ displayTime(orderObj?.drop_rider_assign_at) }}
                        </div>
                      </div>
  
                      <div
                        class="progress-step"
                        [ngClass]="getStepClass(orderObj.status, 5)"
                      >
                        <button
                          class="btn"
                          [ngClass]="{
                            primary:
                              orderObj.status === 5 || orderObj.status === 6,
                            disabled: orderObj.status < 5
                          }"
                          (click)="
                            (orderObj.status === 5 || orderObj.status === 6) &&
                              complete(orderObj.id)
                          "
                        >
                          {{ orderObj.status >= 7 ? "Delivered" : "Mark Done" }}
                        </button>
                        <div *ngIf="orderObj.status >= 7" class="timestamp">
                          {{ displayTime(orderObj?.delivered_at) }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div>
        <app-page-index
    [currentPage]="currentPage"
    [totalPages]="totalPages"
    [orders]="orders"
    (pageChange)="updatePageNumber($event)"
  >
  </app-page-index>
      </div>
    </div>
  </section>