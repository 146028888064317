<div class="search-bar-section">
  <div class="search-container">
    <input 
      class="search-user"
      (keyup)="handleSearch($event)"
      (input)="handleInputChange($event)"
      placeholder="Search Mobile, Email, Name, Booking Code"
    />
    <img class="search-icon" [src]="search" alt="Search Icon" />
  </div>
</div>
