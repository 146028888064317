import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

interface FilterModel {
  dateOption: string;
  applyOn: string;
  fromDate?: string;
  toDate?: string;
}

@Component({
  selector: 'app-customer-directory-date',
  templateUrl: './customer-directory-date.component.html',
  styleUrls: ['./customer-directory-date.component.scss']
})
export class CustomerDirectoryDateComponent {
  @Output() customersLoaded = new EventEmitter<any[]>();

  isOpen = false;
  isLoading = false;
  
  filter: FilterModel = {
    applyOn: '',
    dateOption: ''
  };

  applyOnOptions = [
    'Customer Since',
    'Last Order Date'
  ];

  dateOptions = [
    'Today',
    'Last 7 Days',
    'Last 30 Days',
    'Last Year',
    'Custom'
  ];

  constructor(private apiService: ApiService) {}

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectApplyOn(option: string) {
    this.filter.applyOn = option;
    this.filter.dateOption = ''; // Reset date option
  }

  selectDateOption(option: string) {
    this.filter.dateOption = option;
  }

  onFromDateChange(date: string) {
    this.filter.fromDate = date;
  }

  onToDateChange(date: string) {
    this.filter.toDate = date;
  }

  applyFilter() {
    // Validate input
    if (!this.filter.applyOn || !this.filter.dateOption) {
      alert('Please select Apply On and Date Option');
      return;
    }

    if (this.filter.dateOption === 'Custom' && (!this.filter.fromDate || !this.filter.toDate)) {
      alert('Please select both From and To dates for Custom range');
      return;
    }

    // Set loading state
    this.isLoading = true;

    // Call API service method
    this.apiService.getCustomerDate(this.filter).subscribe({
      next: (response: { status: string; data: any[]; }) => {
        this.isLoading = false;
        
        if (response.status === 'success') {
          this.customersLoaded.emit(response.data);
          this.isOpen = false; // Close dropdown after successful filter
        } else {
          alert('Failed to fetch customers');
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error('Error fetching customers', error);
        alert('Error fetching customers');
      }
    });
  }
}