import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ORDER_LISTING } from '../feature/invoice/invoice-form/drycleaning-filter-constant';
import { ModalComponent } from '../modal/modal.component';
import { InvoiceService } from '../service/invoice.service';
import { UtilityService } from '../service/utility.service';
import { SearchComponent } from './search/search.component';
import { ViewInvoiceComponent } from '../view-invoice/view-invoice.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { OrderReadyModalComponent } from './order-ready-modal/order-ready-modal.component';
import { PaymentModalComponent } from '../feature/dashboard/payment-modal/payment-modal.component';
import { SuccessModalComponent } from '../feature/dashboard/success-modal/success-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { OrderSourceComponent } from './order-source/order-source.component';
import { DateRangeComponent } from './date-range/date-range.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dialog: any;
  orderObj: any;
  @ViewChild(SearchComponent) searchComponent!: SearchComponent;
  @ViewChild(DateRangeComponent) dateRangeComponent: DateRangeComponent;
  @ViewChild(OrderSourceComponent) orderSourceComponent!: OrderSourceComponent;
  isNewOrderListed: boolean = true;
  type: 'NEW' | 'INPRO' = 'NEW'; 
  selectedDateOptions: string[] = [];
  customFromDate: string = '';
  customToDate: string = '';
  public finalAmount: number;
  public drycleaningItemList = [];
  public orderListing = ORDER_LISTING;
  public selectedSources: string[] = [];
  public orders: any = null;
  public multipleBookings: any[] = [];
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;
  public upArrow = `${environment.assetUrl}assets/images/icons/upArrow.svg`;
  public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;
  public cross = `${environment.assetUrl}assets/images/cross.svg`;

  public qrIcon = `${environment.assetUrl}assets/images/icons/qr.svg`;
  public tickCircle = `${environment.assetUrl}assets/images/icons/tick-circle.svg`;
  public tickSquare = `${environment.assetUrl}assets/images/icons/tick-square.svg`;
  public assets = environment.assetUrl;
  public sortQuery: string = '';
  public searchQuery: string = '';
  public selectedFilter: string = '';

  public message: any = '';
  public messageType: number = -1;
  filteredOrders: any[] = [];

  currentPage: number = 1;
  totalPages: number = 10;
  sortColumn: string | null = null;
  sortDirection: 'asc' | 'desc' = 'asc';
  currentBrand: 'uclean' | 'whitetiger' = 'uclean';

  mobileView: boolean = false;
  dateS: any;
  fromDate: any;
  toDate: any;
  selectedApplyOptions: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogRef: MatDialog,
    private utils: UtilityService,
    private invoiceService: InvoiceService,
    private toastrService: ToastrService,
    private apiService: ApiService
  ){}
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.mobileView = event.target.outerWidth <= 820;
  }

  ngOnInit(): void {
    this.getData();
    this.filteredOrders = this.orders;
  }

  sortAscending(column: string) {
    this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortColumn = column;

    this.orders.sort((a, b) => {
      if (a[column] < b[column]) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return '↕'; // or use any default icon
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
    this.getData();
  }


  convertToDate(date:any) {
    return new Date() > new Date(date);
  }

  handleDateChange(dateRange: {fromDate: string, toDate: string, dateS: string}) {
    if (dateRange.fromDate !== '' && dateRange.toDate !== '' && dateRange.dateS !== 'Select') {
      this.getData();
    }
  }

  getStepClass(currentStatus: number, stepStatus: number): string {
    if (currentStatus > stepStatus) {
      return 'completed';
    } else if (currentStatus === stepStatus) {
      return 'active';
    }
    return '';
  }

  selectAll() {
    console.log(" selectAll is working");
    if (this.multipleBookings?.length !== 0) {
      this.multipleBookings = [];
    } else {
      this.orders.forEach((e: any) => {
        this.multipleBookings.push(e);
      });
    }
    console.log(this.multipleBookings);
  }

  updatePageNumber(event: { page: number }) {
    this.currentPage = event.page;
    if (this.searchQuery !== '') {
      return this.searchBooking();
    }
    this.getData();
  }
  printBarCode(id: number) {
    this.router.navigateByUrl(`barcode/${id}`);
  }

  // renderIcons = (num: number) => {
  //   switch (num) {
  //     case 1:
  //       return `${environment.assetUrl}assets/images/icons/order-through/walk.svg`;
  //     case 2:
  //       return `${environment.assetUrl}assets/images/icons/order-through/phone.svg`;
  //     case 3:
  //       return `${environment.assetUrl}assets/images/icons/order-through/store.svg`;
  //     case 4:
  //       return `${environment.assetUrl}assets/images/icons/order-through/mobile.svg`;
  //     case 5:
  //       return `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`;
  //     case 6:
  //       return `${environment.assetUrl}assets/images/icons/order-through/whatsapp.svg`;
  //     default:
  //       console.warn(`Unexpected orderthrough_id: ${num}`);
  //       return `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`;
  //   }
  // }
  renderIcons = (num: number) => {
    switch (num) {
      case 1:
        return `${environment.assetUrl}assets/images/icons/new-icons/walkin.svg`;
      case 2:
        return `${environment.assetUrl}assets/images/icons/new-icons/customercare.svg`;
      case 3:
        return `${environment.assetUrl}assets/images/icons/new-icons/store.svg`;
      case 4:
        return `${environment.assetUrl}assets/images/icons/new-icons/app.svg`;
      case 5:
        return `${environment.assetUrl}assets/images/icons/new-icons/web.svg`;
      case 6:
        return `${environment.assetUrl}assets/images/icons/new-icons/whatsapp.svg`;
      default:
        console.warn(`Unexpected orderthrough_id: ${num}`);
        return `${environment.assetUrl}assets/images/icons/new-icons/walk.svg`;
    }
  }



  openConfirmation(
    id: number | Array<number>,
    postURL: string,
    title: string,
    body: { status: number },
    del: number = 0,
    modalComponent: Type<any> // Add this parameter
  ) {
    const modal = this.dialogRef.open(modalComponent, {
      data: {
        bookingId: id,
        messageToDisplay: title,
        postURL,
        body,
        delete: del,
      },
    });
    modal.componentInstance.trigger.subscribe((data) => {
      this.getData();
    });
  }
  
  updateOrder(orderId: number | Array<number>) {
    this.openConfirmation(
      orderId,
      `bookings/updateStatus/${orderId}`,
      'Are you sure you want to mark this order as Ready?',
      {
        status: 4,
      },
      0,
      OrderReadyModalComponent
    );
  }
  
  handleCancel(id: number) {
    this.openConfirmation(
      id,
      `bookings/updateStatus/${id}`,
      'Are you sure you want to cancel this order?',
      {
        status: 0,
      },
      1,
      CancelModalComponent
    );
  }
  
  complete(orderId: number) {
    console.log("Complete method called for orderId:", orderId);
    const dialogRef = this.openPaymentModal(orderId, this.finalAmount);
    dialogRef.afterClosed().subscribe(result => {
      console.log("Payment modal closed. Result:", result);
      if (result && result.statusResponse && result.statusResponse.status === 'success') {
        console.log("Order status update response:", result.statusResponse);
        this.updateOrderInList(orderId);
      } else {
        console.warn("Status not updated to 7, check statusResponse.");
      }
    });
  }
  
  
  openPaymentModal(bookingId: number, finalAmount: number) {
    console.log("Opening payment modal for bookingId:", bookingId, "with final amount:", finalAmount);
    return this.dialogRef.open(PaymentModalComponent, {
      width: '30%',
      data: { bookingId: bookingId, finalAmount: finalAmount }
    });
  }
  
  updateOrderInList(orderId: number) {
    console.log("Updating order status in the list for orderId:", orderId);
    const orderIndex = this.orders.findIndex(order => order.id === orderId);
    if (orderIndex !== -1) {
      this.orders[orderIndex].status = 7;
      console.log("Order status set to 7 for order at index:", orderIndex);
      this.orders = [...this.orders]; 
      this.orderObj = this.orders[orderIndex];
      console.log("Updated orderObj:", this.orderObj);
    } else {
      console.warn("Order not found in the list.");
    }
  }


  showSuccessModal() {
    this.dialog.open(SuccessModalComponent, {
      width: '30%'
    });
  }
  
  viewInvoice = (id: number) => {
    this.invoiceService.previewInvoice(id).subscribe((data) => {
      const reader = new FileReader();
      reader.readAsText(data);

      reader.onloadend = () => {
        this.dialogRef.open(ViewInvoiceComponent, {
            data: reader.result!.toString(),
            width: '230mm', 
            height: '230mm', 
            panelClass: 'a4-dialog'
        });
      };
    });
  }

  openModal(
    id: number | number[],
    fetchURL: string,
    postURL: string,
    title: string
    ) {
    const modal = this.dialogRef.open(ModalComponent, {
      data: {
        fetchURL,
        postURL,
        bookingId: Array.isArray(id) ? id : id,
        title,
      },
    });

    modal.componentInstance.trigger.subscribe((data: any) => {
      this.multipleBookings = [];
      this.getData();
    });
  }

  assignPickup(id: number | number[]) {
    this.openModal(
      id,
      'runners?page=1&status=1',
      'bookings/assignPickup',
      'Assign Pickup'
    );
  }

  isSelectedInMultiple(id: number) {
    const isSelected = this.multipleBookings.some(booking => booking.id === id);
    return isSelected;
}


handleAddOrRemoveFromBookings(event: any, booking: any) {
    console.log("handleAddOrRemoveFromBookings is Working");
    
    // Log the current state of multipleBookings
    console.log("Current multipleBookings:", this.multipleBookings);
    
    const index = this.multipleBookings.findIndex((e: any) => e.id === booking.id);
    
    if (index === -1) {
        this.multipleBookings.push(booking);
        console.log(`Added booking with ID ${booking.id}`);
    } else {
        this.multipleBookings.splice(index, 1);
        console.log(`Removed booking with ID ${booking.id}`);
    }
    
    // Log the updated state of multipleBookings
    console.log("Updated multipleBookings:", this.multipleBookings);
}
  onSearchPerformed(searchResult: { query: string, orders: any[], totalPages: number }) {
    this.searchQuery = searchResult.query;
    this.orders = searchResult.orders;
    this.totalPages = searchResult.totalPages;
    this.currentPage = 1;
  }

  changeOrderType(newType: 'NEW' | 'INPRO') {
    this.type = newType;
    this.isNewOrderListed = newType === 'NEW';
    if (this.searchComponent) {
      this.searchComponent.updateOrderType(newType);
    }
    this.getData();
  }

  searchBooking() {
    this.apiService.searchBooking(
      this.searchQuery,
      this.type,
      this.sortQuery,
      this.currentPage,
      this.selectedFilter
    ).subscribe((data: any) => {
      this.orders = data.data;
      this.totalPages = data.totalPages;
    });
  }

  onSourcesChanged(selectedSources: string[]): void {
    this.selectedSources = selectedSources;
    this.getData();
  }
  onDateRangeChange(dateRange: { fromDate: string, toDate: string, selectedOptions: string[], selectedApplyOptions: string[] }) {
    console.log('Date range changed:', dateRange);
    this.selectedDateOptions = dateRange.selectedOptions;
    this.customFromDate = dateRange.fromDate;
    this.customToDate = dateRange.toDate;
    this.selectedApplyOptions = dateRange.selectedApplyOptions;
    console.log('Selected date options:', this.selectedDateOptions);
    console.log('Custom date range:', this.customFromDate, 'to', this.customToDate);
    console.log('Selected apply options:', this.selectedApplyOptions);
    this.getData();
  }

  getData() {
    console.log('Getting data with current filters');
    this.apiService.getBookings(
      this.currentPage,
      this.type,
      this.sortQuery,
      this.selectedFilter,
      this.dateS,
      this.fromDate,
      this.toDate,
      ''
    ).subscribe((data: any) => {
      console.log('Received data from API:', data);
      this.orders = this.filterOrdersBySource(data.data);
      console.log('Orders after source filtering:', this.orders.length);
      this.orders = this.filterOrdersByDate(this.orders);
      console.log('Orders after date filtering:', this.orders.length);
      this.filteredOrders = this.orders;
      this.totalPages = Math.ceil(this.orders.length / 10);
      if (this.orders.length > 0) {
        this.finalAmount = this.orders[0].final_amount;
        console.log("Final amount set in getData:", this.finalAmount);
      } else {
        console.log("No data found in response.");
      }
    });
  }

  filterOrdersByDate(orders: any[]): any[] {
    console.log('Filtering orders by date');
    if (this.selectedDateOptions.length === 0) {
      console.log('No date filter applied');
      return orders;
    }
  
    const today = moment().startOf('day');
  
    return orders.filter(order => {
      if (this.type === 'NEW') {
        const orderDate = moment(order.pickup_date, 'YYYY-MM-DD');
        return this.filterByDateOptions(orderDate, today);
      } else if (this.type === 'INPRO') {
        if (this.selectedApplyOptions.length === 0) {
          // If no "Apply On" options are selected, use pickup_date by default
          const pickupDate = moment(order.pickup_date, 'YYYY-MM-DD');
          return this.filterByDateOptions(pickupDate, today);
        } else {
          // If "Apply On" options are selected, check each selected option
          return this.selectedApplyOptions.some(applyOption => {
            let dateToCheck: moment.Moment;
            switch (applyOption) {
              case 'Order Date':
                dateToCheck = moment(order.created_at, 'YYYY-MM-DD');
                break;
              case 'Pickup Date':
                dateToCheck = moment(order.pickup_date, 'YYYY-MM-DD');
                break;
              case 'Delivery Date':
                dateToCheck = moment(order.delivery_date, 'YYYY-MM-DD');
                break;
              case 'Invoice Date':
                dateToCheck = moment(order.in_process_at, 'YYYY-MM-DD');
                break;
              default:
                return false;
            }
            return this.filterByDateOptions(dateToCheck, today);
          });
        }
      }
      return true;
    });
  }
  
  private filterByDateOptions(dateToCheck: moment.Moment, today: moment.Moment): boolean {
    return this.selectedDateOptions.some(option => {
      switch (option) {
        case 'Today':
          return dateToCheck.isSame(today, 'day');
        case 'Last 7 Days':
          return dateToCheck.isBetween(today.clone().subtract(7, 'days'), today, 'day', '[]');
        case 'Last 30 Days':
          return dateToCheck.isBetween(today.clone().subtract(30, 'days'), today, 'day', '[]');
        case 'Last Year':
          return dateToCheck.isBetween(today.clone().subtract(1, 'year'), today, 'day', '[]');
        case 'Custom':
          const fromDate = this.customFromDate ? moment(this.customFromDate, 'DD/MM/YYYY') : null;
          const toDate = this.customToDate ? moment(this.customToDate, 'DD/MM/YYYY') : null;
          if (fromDate && toDate) {
            return dateToCheck.isBetween(fromDate, toDate, 'day', '[]');
          } else if (fromDate) {
            return dateToCheck.isSameOrAfter(fromDate, 'day');
          } else if (toDate) {
            return dateToCheck.isSameOrBefore(toDate, 'day');
          }
          return true;
        default:
          return true;
      }
    });
  }
  filterOrdersBySource(orders: any[]): any[] {
    if (this.selectedSources.length === 0) {
      return orders;  // Return all orders if no sources are selected
    }
    return orders.filter(order => 
      this.selectedSources.includes(this.getSourceFromOrderThroughId(order.orderthrough_id))
    );
  }

 

  getSourceFromOrderThroughId(orderthroughId: number): string {
    switch (orderthroughId) {
      case 1: return 'Walk-In';
      case 2: return 'Customer Care';
      case 3: return 'Store';
      case 4: return 'Mobile App';
      case 5: return 'Website';
      case 6: return 'Whatsapp';
      default: return 'Website';
    }
  }


  displayTime = (datetime: string) => {
    if (datetime){
      return moment.utc(datetime).local().format('lll');
    }else{
      return '';
    }
  }

  formatTime = (date: string, time: string) => {
    if (!date || date === ''){
      return '';
    }
    if (time){
      return moment(date).format('DD/MM/YYYY') + ' ' + time;
    } else{
      return moment(date).format('DD/MM/YYYY');
    }
  }

  formatAddress = (address: string) => address.replace(/, ,/g, ',');

  gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }

  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      return false;
    }
  }

 

  assignDrop = (id: number | number[]) => {
    this.openModal(id, 'runners?page=1&status=1', 'bookings/assignDrop', 'Assign Drop');
  }
}