<div class="dropdown-container">
    <button class="dropdown-box" (click)="toggleDropdown()">
      <div class="button-content">
        <span class="text-content">Order Source</span>
        <img [src]="dropIcon" alt="dropdown-icon" />
      </div>
    </button>
    <div *ngIf="isOpen" class="dropdown-content">
      <label *ngFor="let source of sources" [class.selected]="selectedSources.includes(source)">
        <input type="checkbox" 
               [checked]="selectedSources.includes(source)"
               (change)="toggleSource(source)">
        <span class="checkmark"></span>
        {{ source }}
      </label>
    </div>
  </div>
